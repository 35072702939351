/**
 * Basic Element styles that are used in multiple Forms
 */

export const basicContainer = {
  maxWidth: '44.25rem',
  m: '0 auto',
};

export const smallBasicContainer = {
  maxWidth: '32rem',
  m: '0 auto',
};

export const styleContentTop = {
  ...basicContainer,
  textAlign: 'center',
  padding: 4,
  paddingTop: 5,
};

export const stylePanel = {
  width: '100%',
  margin: 0,
  px: 2,
  py: 2.5,
  backgroundColor: 'grey.light',
};

export const styleCardHeader = {
  borderBottom: '0.0625rem solid',
  borderColor: 'grey.main',
  py: 1.5,
  px: 4,
};
