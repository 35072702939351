import { Box, CircularProgress } from '@packages/shared';
import type { ReactNode } from 'react';

/**
 * Wrapper for forms that renders the Loader on a white background on top of the form if showLoader
 * is set to true.
 *
 * If you don't provide children you should provide a parent
 * element with "position: relative".
 */
export const ActivityOverlay = ({
  showLoader,
  children,
}: {
  showLoader: boolean;
  children?: ReactNode;
}) => (
  <Box
    {...(children && {
      sx: {
        position: 'relative',
      },
    })}
  >
    {children}
    {showLoader && (
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1010,
          backgroundColor: 'rgba(250, 250, 250, 0.9)',
        }}
      >
        <CircularProgress size="l" />
      </Box>
    )}
  </Box>
);
